import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatToolbar} from '@angular/material/toolbar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../app.component';
import {AuthenticationService} from '../authentication/authentication.service';
import {User} from '../shared/models/user.model';
import {LocalizationService} from '../shared/services/localization.service';

@Component({
    selector: 'app-topmenu',
    styleUrls: ['app.topmenu.component.scss'],
    templateUrl: 'app.topmenu.component.html',
})
export class AppTopmenuComponent implements OnInit, AfterViewInit{

    authenticatedUser: User;
    @Input() background: string;
    @Input() color: string;
    @Input() logo: string;

    @ViewChild('stickyMenu') menuElement: MatToolbar;
    sticky = false;
    elementPosition: any;
    public menuConfig: any[];

    constructor(
        public app: AppComponent,
        private authenticationService: AuthenticationService,
        private localizationService: LocalizationService,
        private translate: TranslateService,
        private translateService: TranslateService,
        private authService: AuthenticationService,
        private router: Router,
    ) {
        this.authenticationService.user.subscribe((user: User) => {
            this.authenticatedUser = user;
        });
    }

    ngOnInit(): void {
        const clientMenuConfig = [
            {label: 'Offers', icon: 'local_offer', routerLink: ['/offers']},
            {label: 'Campaigns', icon: 'folder', routerLink: ['/campaigns']},
            {label: 'Contracts', icon: 'insert_drive_file', routerLink: ['/contracts']},
        ];

        const adminMenuConfig = [
            {label: 'Planning', icon: 'assignment', routerLink: ['/planning']},
            {label: 'Offers', icon: 'local_offer', routerLink: ['/offers']},
            {label: 'Campaigns', icon: 'folder', routerLink: ['/campaigns']},
            {label: 'Controlling', icon: 'insert_chart', routerLink: ['/controlling']},
            {label: 'Financial', icon: 'insert_chart', routerLink: ['/financial']},
            {label: 'Calendar', icon: 'date_range', routerLink: ['/calendar']},
            {label: 'Dashboard', icon: 'insert_chart', routerLink: ['/dashboard']},
        ];

        this.menuConfig = this.authService.isAdminUser() ? adminMenuConfig : clientMenuConfig;
    }

    ngAfterViewInit(): void {
        this.elementPosition = this.menuElement._elementRef.nativeElement.offsetTop;
    }

    getActiveClass(item): string {
        return this.router.url === item.routerLink[0] ? 'active' : '';
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll(): void {
        const windowScroll = window.pageYOffset;
        this.sticky = windowScroll >= this.elementPosition;
    }
}
