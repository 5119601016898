import {EnumField, IEnum} from '../decorators/enum-field.decorator';
import {Affinity} from '../services/api/affinity.service';
import {Action} from './action.model';
import {Offer} from './offer.model';
import {Sending} from './sending.model';
import {SerializableObject} from './serializable-object';
import {Template} from './template.model';

export const CAMPAIGN_STATUSES: IEnum[] = [
    {value: 0, label: 'Draft', icon: ''},
    {value: 1, label: 'Template missing', icon: 'priority_high'},
    {value: 2, label: 'Template pending approval', icon: 'priority_high'},
    {value: 3, label: 'Template approved', icon: 'done'},
    {value: 4, label: 'Sendout sending', icon: 'done'},
    {value: 5, label: 'Sendout done', icon: 'done'},
];

export enum CampaignPaymentStatus {
    Running,
    ReadyToInvoice,
    Open,
    Paid,
}

export const CAMPAIGN_PAYMENT_STATUSES: IEnum[] = [
    {value: CampaignPaymentStatus.Running, label: 'RUNNING'},
    {value: CampaignPaymentStatus.ReadyToInvoice, label: 'READY TO INVOICE'},
    {value: CampaignPaymentStatus.Open, label: 'OPEN'},
    {value: CampaignPaymentStatus.Paid, label: 'PAID'},
];

export class CampaignParams extends SerializableObject {
    public id: number;
    public campaign_id: number;
    public cpm: number;
    public cpc: number;
    public cpl: number;
    public cpo: number;
    public cps: number;
    public openers: number;
    public clicks: number;
    public volume: number;
    public type: string;
}

@EnumField('status', CAMPAIGN_STATUSES)
export class Campaign extends SerializableObject {
    public id: number;
    public public_id: string;
    public offer_id: number;
    public affinity: Affinity;
    public affinity_id: number;
    public name: string;
    public serialized_billing_types: string;
    public date: Date;
    public stop_date: Date;
    public condition: string;
    public amounts: CampaignParams[];
    public templates: Template[];
    public is_draft: boolean;
    public status: number;
    public payment_status: number;
    public invoice_number: string;
    public has_blacklist: boolean;
    public offer: Offer;
    public affinity_stats: CampaignParams;
    public customer_stats: CampaignParams;
    public sendings: Sending[];
    public send_cpm_cost: number;
    public bounce_cost: number;
    public unsubscribe_cost: number;
    public billing_price: number;
    public realOpensBySentPercent: number;
    public realClicksBySentPercent: number;
    public rtkp: number;
    public etkp: number;
    public actions: Action[];

    protected stats: any;

    public generateStats() {
        // Helpers
        function percent(a, b) {
            return b === 0 ? 0 : a / b;
        }

        function sum(items, property) {
            return items.reduce((total, item) => total + item[property], 0);
        }

        // Generate stats
        const goal = this.amounts.find((a) => a.type === 'goal');
        const price = this.amounts.find((a) => a.type === 'price');
        const real = this.amounts.find((a) => a.type === 'real');

        const total = (goal.cpm / 1000) * price.cpm +
            real.cpc * price.cpc +
            real.cpl * price.cpl +
            real.cpo * price.cpo +
            real.cps * price.cps;

        const turnover = {
            total: total,
            cpm: goal.cpm * price.cpm / 1000,
            cpc: real.cpc * price.cpc,
            cpl: real.cpl * price.cpl,
            cpo: real.cpo * price.cpo,
            cps: real.cps * price.cps,
        };

        const realLeads = real.cpc + real.cpl + real.cpo + real.cps;

        const hasSendings = this.sendings && this.sendings.length > 0;
        const sendings = hasSendings ? this.sendings : [];

        const bookedVolume = goal.cpm;

        const sentVolume = sum(sendings, 'volume_sent');
        const totalBounced = sendings.reduce((total, s) => total + (+s.bounces_hard), 0);
        const totalUnsubscribed = sum(sendings, 'unsubscriptions');
        const netSentVolume = sendings.reduce(
            (total, s) => total + (+s.volume_sent) - ((+s.bounces_hard) + (+s.unsubscriptions)), 0);
        const realOpens = sum(sendings, 'opens_unique');
        const realClicks = sum(sendings, 'clicks_unique');
        const plannedClicks = goal.clicks;
        const plannedOpens = goal.openers;

        const sentCosts = (sentVolume / 1000) * this.send_cpm_cost;
        const failedCosts = (totalBounced * this.bounce_cost) + (totalUnsubscribed * this.unsubscribe_cost);

        const costs = sentCosts + failedCosts;
        const earnings = turnover.total - costs;
        const totalSum = sentVolume * price.cpm / 1000 + real.cpc * price.cpc + real.cpl * price.cpl + real.cpo * price.cpo + real.cps * price.cps;

        const achievement = {
            cpm: sentVolume / bookedVolume,
            cpc: real.cpc / goal.cpc,
            cpl: real.cpl / goal.cpl,
            cpo: real.cpo / goal.cpo,
            cps: real.cps / goal.cps,
            opens: realOpens / goal.openers,
            clicks: realClicks / goal.clicks,
        };

        const applicableAchs = {
            ...(bookedVolume > 0 && {cpm: achievement.cpm}),
            ...(goal.cpc > 0 && {cpc: achievement.cpc}),
            ...(goal.cpl > 0 && {cpl: achievement.cpl}),
            ...(goal.cpo > 0 && {cpo: achievement.cpo}),
            ...(goal.cps > 0 && {cps: achievement.cps}),
            ...(goal.openers > 0 && {opens: achievement.opens}),
            ...(goal.clicks > 0 && {clicks: achievement.clicks}),
        };

        const lowestGoal = Object.keys(applicableAchs).length ? Math.min(...Object.values(applicableAchs)) : null;

        // Cache stats
        this.stats = {
            turnover,
            costs,
            earnings,
            bookedVolume,
            sentVolume,
            plannedClicks,
            realClicks,
            plannedOpens,
            realOpens,
            totalSum,
            realLeads,
            achievement,
            lowestGoal,
            internalOpensByAffinityPercent: this.affinity_stats ? percent(this.affinity_stats.openers,
                this.affinity_stats.volume) : null,
            customerClicksByAffinityPercent: this.affinity_stats ? percent(this.affinity_stats.clicks,
                this.affinity_stats.volume) : null,
            internalOpensByCustomerPercent: this.customer_stats ? percent(this.customer_stats.openers,
                this.customer_stats.volume) : null,
            customerClicksByCustomerPercent: this.customer_stats ? percent(this.customer_stats.clicks,
                this.customer_stats.volume) : null,
            sentVolumePercent: percent(sentVolume, bookedVolume),
            realClicksByBookedPercent: percent(realClicks, bookedVolume),
            realClicksBySentPercent: percent(realClicks, netSentVolume),
            realOpensByBookedPercent: percent(realOpens, bookedVolume),
            realOpensBySentPercent: percent(realOpens, netSentVolume),
            plannedOpensByBookedPercent: percent(plannedOpens, bookedVolume),
            plannedClicksByBookedPercent: percent(plannedClicks, bookedVolume),
            rtkp: bookedVolume ? 1000 * earnings / bookedVolume : 0,
            etkp: sentVolume ? 1000 * earnings / sentVolume : 0,
        };
    }

    public getStats() {
        // If already calculated, return cached stats
        if (this.stats) {
            return this.stats;
        }

        // Calculate statistics
        this.generateStats();

        return this.stats;
    }

    protected coercePropertyType(propertyName: string, propertyValue: any) {
        switch (propertyName) {
            case 'affinity_stats':
            case 'amounts':
            case 'customer_stats':
                if (propertyValue.length > 0) {
                    return propertyValue.map((c) => new CampaignParams(c));
                }
                return propertyValue;
            case 'sendings':
                if (propertyValue.length > 0) {
                    return propertyValue.map((c) => new Sending(c));
                }
                return propertyValue;
            case 'templates':
                if (propertyValue.length > 0) {
                    return propertyValue.map((c) => new Template(c));
                }
                return propertyValue;
            case 'actions':
                if (propertyValue.length > 0) {
                    return propertyValue.map((a) => new Action(a));
                }
                return propertyValue;
            default:
                return propertyValue;
        }
    }

}
