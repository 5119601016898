import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {AuthenticationResolver} from './authentication/authentication.resolver';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {CompanyResolver} from './shared/services/api/company.resolver';
import {UserRole} from './shared/models/user.model';

export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        resolve: {
            authToken: AuthenticationResolver,
            company: CompanyResolver,
        },
    },
    {
        path: '',
        component: HomeComponent,
        resolve: {
            authToken: AuthenticationResolver,
            company: CompanyResolver,
        },
        children: [
            // Redirect homepage to planning because we don't have yet a dashboard
            {
                path: '', 
                redirectTo: '/planning',
                pathMatch: 'full',
            },
            {
                path: 'logout',
                component: LogoutComponent,
                canActivate: [AuthenticationGuard],
            },
            {
                path: 'planning',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule),
            },
            {
                path: 'offers',
                canActivate: [AuthenticationGuard],
                loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
            },
            {
                path: 'campaigns',
                canActivate: [AuthenticationGuard],
                loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule),
            },
            {
                path: 'controlling',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./controlling/controlling.module').then(m => m.ControllingModule),
            },
            {
                path: 'financial',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule),
            },
            {
                path: 'dashboard',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'calendar',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./calendar/calendar.module').then(m => m.PlanningCalendarModule),
            },
            {
                path: 'admin',
                canActivate: [AuthenticationGuard],
                data: {expectedRole: UserRole.Employee},
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
            },
        ],
    },
    /*{ path: '**', component: PageNotFoundComponent }*/
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'});
