<div
    [ngClass]="{'layout-wrapper-menu-active':sidebarActive, 'layout-overlay-menu':overlay}" class="layout-wrapper"
>

    <app-topbar
        [logo]="company.brand_app_logo"
    ></app-topbar>

    <app-topmenu
        [logo]="company.brand_app_logo"
    ></app-topmenu>

    <div class="layout-main">
        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>

<div *ngIf="showWarningDevelopment" class="development-message">
    <div>Development</div>
</div>

<div *ngIf="showWarningStaging" class="staging-message">
    <div>Staging</div>
</div>

<div *ngIf="showWarningProduction" class="production-message">
    <div>***Alert*** Production ***Alert***</div>
</div>
