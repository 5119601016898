import {Component, Input} from '@angular/core';
import {AppComponent} from './app.component';
import {AuthenticationService} from './authentication/authentication.service';
import {User} from './shared/models/user.model';
import {CampaignService} from './shared/services/api/campaign.service';
import {LocalizationService} from './shared/services/localization.service';

@Component({
    selector: 'app-topbar',
    styleUrls: ['app.topbar.component.scss'],
    templateUrl: 'app.topbar.component.html',
})
export class AppTopbarComponent {
    authenticatedUser: User;
    @Input() background: string;
    @Input() color: string;
    @Input() logo: string;

    public taxValue: string;

    constructor(
        public app: AppComponent,
        private authenticationService: AuthenticationService,
        private localizationService: LocalizationService,
        private campaignService: CampaignService,
    ) {
        this.authenticationService.user.subscribe((user: User) => {
            this.authenticatedUser = user;
        });

        this.campaignService.vat$.subscribe((vat) => {
            this.taxValue = `${vat}`;
        });
    }

    useLanguage(language: string) {
        this.localizationService.setLanguage(language);
    }

    public isAdminUser(): boolean {
        return this.authenticationService.isAdminUser();
    }

    public vatChanged(result): void {
        this.campaignService.updateVat(parseFloat(result.value));
    }
}
