<div [style.background-color]="background" class="topbar clearfix">
    <div class="logo">
        <a href="#">
            <img
                [src]="logo"
                alt="Logo"
            >
        </a>
    </div>

    <a (click)="app.onTopbarMenuButtonClick($event)" [style.color]="color" href="#" id="topbar-menu-button">
        <i class="material-icons">menu</i>
    </a>

    <ul
        [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}"
        class="topbar-menu fadeInDown"
        style="line-height: 26px;"
    >
        <li
            #profile
            *ngIf="authenticatedUser"
            [ngClass]="{'active-topmenuitem':app.activeTopbarItem === profile}"
            class="profile-item"
        >
            <a (click)="app.onTopbarItemClick($event,profile)" [style.color]="color" href="#">
                <div class="profile-image">
                    <i class="fa ui-icon-person"></i>
                </div>
                <div class="profile-info">
                    <span class="topbar-item-name profile-name">{{ authenticatedUser?.name }}</span>
                </div>
            </a>

            <ul class="fadeInDown">
                <li
                    role="menuitem"
                    class="languages"
                >
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                        <mat-button-toggle
                            (click)="useLanguage('en')"
                        >
                            <mat-icon>language</mat-icon>
                            en
                        </mat-button-toggle>

                        <mat-button-toggle
                            (click)="useLanguage('de')"
                        >
                            <mat-icon>language</mat-icon>
                            de
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </li>

                <li
                    role="menuitem"
                    class="vat-selector-container"
                >
                    <span class="label">VAT</span>
                    <mat-button-toggle-group
                        class="vat-selector"
                        name="vat"
                        [ngModel]="taxValue"
                        (change)="vatChanged($event)"
                    >
                        <mat-button-toggle value="0.16">16%</mat-button-toggle>
                        <mat-button-toggle value="0.19">19%</mat-button-toggle>
                    </mat-button-toggle-group>
                </li>

                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/settings' ]">
                        <i class="material-icons">tune</i>
                        <span>{{'Settings' | translate}}</span>
                    </a>
                </li>
                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/users' ]">
                        <i class="material-icons">group</i>
                        <span>{{'Users' | translate}}</span>
                    </a>
                </li>
                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/esp-config' ]">
                        <i class="material-icons">emails</i>
                        <span>{{'Esp Config' | translate}}</span>
                    </a>
                </li>
                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/template-variables' ]">
                        <i class="material-icons">code</i>
                        <span>{{'Template variables' | translate}}</span>
                    </a>
                </li>
                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/template-assets' ]">
                        <i class="material-icons">code</i>
                        <span>{{'Template assets' | translate}}</span>
                    </a>
                </li>
                <li *ngIf="isAdminUser()" role="menuitem">
                    <a [routerLink]="[ '/admin/email-test' ]">
                        <i class="material-icons">emails</i>
                        <span>{{'Email test' | translate}}</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a [routerLink]="[ '/logout' ]">
                        <i class="material-icons">power_settings_new</i>
                        <span>{{'Abmelden' | translate}}</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>
