import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';

@Component({
    selector: 'app-logout',
    template: '',
})
export class LogoutComponent implements OnInit {

    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    ngOnInit() {
        this.authenticationService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        });
    }

}
