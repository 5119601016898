<mat-toolbar #stickyMenu [class.sticky]="sticky">
    <div>
        <ng-container *ngFor="let item of menuConfig">
            <a mat-button class="list-item {{ getActiveClass(item) }}" [routerLink]="item.routerLink">
                <mat-icon>{{item.icon}}</mat-icon>
                {{item.label | translate}}
            </a>
        </ng-container>
    </div>
</mat-toolbar>
