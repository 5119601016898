import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationResolver implements Resolve<any> {

    constructor(private authenticationService: AuthenticationService) {
    }

    resolve() {
        return this.authenticationService.refreshTokenAndSetRenewTimeout();
    }
}
