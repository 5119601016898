import {cloneDeep, isEqual} from 'lodash-es';

/**
 * Base Domain Object
 */
export abstract class SerializableObject {

    /**
     * Domain object constructor.
     * @param json Optional json used to populate the domain object fields.
     */
    constructor(json?: Object) {
        // TODO: Refactor constructors with type arguments and remove this extra check.
        if (!this.isNil(json)) {
            this.buildFromJSON(json);
        }
    }

    /**
     * Builds the domain object fields from the provided json.
     * @param json The json object
     */
    public buildFromJSON(json: Object) {
        if (this.isNil(json)) {
            return;
        }

        let primitiveProperties: string[] = Object.keys(json);
        primitiveProperties.forEach((property: string) => {
            this[property] = this.coercePropertyType(property, json[property]);
        });
    }

    /**
     * Clones the current SerializableObject.
     */
    public clone() {
        return cloneDeep(this);
    }

    /**
     * Performs a deep comparison between objects to determine if they are equivalent.
     */
    public isClone(otherObj: Object): boolean {
        if (this === otherObj) {
            // Don't count the references to the same object as equivalent objects.
            return false;
        }

        return isEqual(this, otherObj);
    }

    /**
     * Coerce property value type. Override this method to convert properties to proper types.
     *
     * @param property The property name
     * @param value The property value
     */
    protected coercePropertyType(property: string, value: any) {
        if (this.isNil(value)) {
            return null;
        }
        return value;
    }

    private _isNil(object: any): object is undefined | null {
        return object === undefined || object === null;
    }

    private isNil(object: Object, keyStack?: string): boolean {
        if (this._isNil(object)) {
            return true;
        }

        if (keyStack && keyStack !== '') {
            let value = object;
            for (let key of keyStack.split('.')) {
                value = value[key];
                if (this._isNil(value)) {
                    return true;
                }
            }
        }

        return false;
    }
}
