<div mat-dialog-title>
    <div
        [style.background-color]="company.brand_app_background"
        class="login-header"
    >
        <img
            [src]="company.brand_app_logo"
            alt="Logo"
        />
    </div>
</div>
<mat-dialog-content>
    <mat-tab-group (selectedIndexChange)="onTabChange($event)" [selectedIndex]="tabIndex">
        <mat-tab label="{{'Anmeldung' | translate}}">
            <div *ngIf="message" class="message {{message.severity}}">
                <mat-icon *ngIf="message.severity === 'error'">error</mat-icon>
                <mat-icon *ngIf="message.severity === 'success'">check</mat-icon>
                <p>
                    <strong>{{message.summary}}</strong><br/>
                    {{message.detail}}
                </p>
            </div>
            <div class="form">
                <div class="input">
                    <label for="input-login-email">{{ 'E-Mail-Adresse' | translate }}</label>
                    <input
                        [(ngModel)]="credentials.email"
                        id="input-login-email"
                        pInputText
                        type="text"
                    >
                </div>
                <div class="input">
                    <label for="input-login-password">{{'Kennwort' | translate}}</label>
                    <input
                        [(ngModel)]="credentials.password"
                        id="input-login-password"
                        pInputText
                        type="password"
                    >
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="'Kennwort vergessen' | translate">
            <div *ngIf="message" class="message {{message.severity}}">
                <mat-icon *ngIf="message.severity === 'error'">error</mat-icon>
                <mat-icon *ngIf="message.severity === 'success'">check</mat-icon>
                <p>
                    <strong>{{message.summary}}</strong><br/>
                    {{message.detail}}
                </p>
            </div>
            <div class="form">
                <p>{{ 'Bitte geben Sie die E-Mail-Adresse Ihres Benutzerkontos ein. Das neue Kennwort wird an diese Adresse gesendet.' | translate }}</p>
                <div class="input">
                    <label for="input-reset-email">{{ 'E-Mail-Adresse' | translate }}</label>
                    <input
                        [(ngModel)]="credentials.email"
                        id="input-reset-email"
                        pInputText
                        type="text"
                    >
                </div>
            </div>
        </mat-tab>
        <mat-tab [label]="'Request Login' | translate">
            <div *ngIf="message" class="message {{message.severity}}">
                <mat-icon *ngIf="message.severity === 'error'">error</mat-icon>
                <mat-icon *ngIf="message.severity === 'success'">check</mat-icon>
                <p>
                    <strong>{{message.summary}}</strong><br/>
                    {{message.detail}}
                </p>
            </div>
            <div class="form">
                <p>{{ 'If you are an associated user and you don\'t have an account, introduce your e-mail and we will send you your credentials' | translate }}</p>
                <div class="input">
                    <label for="input-reset-email">{{ 'E-Mail-Adresse' | translate }}</label>
                    <input
                        [(ngModel)]="credentials.email"
                        id="input-create-user"
                        pInputText type="text"
                    >
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
    <div>
        <button
            *ngIf="tabIndex === 0"
            (click)="login()"
            type="button"
        >
            <mat-icon>person</mat-icon>
            {{ 'Anmelden' | translate }}
        </button>
        <button
            *ngIf="tabIndex === 1"
            (click)="resetPassword()"
            type="button"
        >
            <mat-icon>https</mat-icon>
            {{ 'Kennwort zurücksetzen' | translate }}
        </button>
        <button
            *ngIf="tabIndex === 2"
            (click)="createLogin()"
            type="button"
        >
            <mat-icon>person</mat-icon>
            {{ 'Create Login' | translate }}
        </button>
    </div>
</mat-dialog-actions>

