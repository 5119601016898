import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../authentication/authentication.service';
import {AuthenticationModel} from '../../shared/models/authentication.model';
import {DOCUMENT} from '@angular/common';

interface FormMessage {
    severity: string;
    summary?: string;
    detail?: string;
}

@Component({
    selector: 'app-login-dialog',
    templateUrl: './login-dialog.component.html',
    styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit, AfterViewChecked {

    public credentials: AuthenticationModel;
    public message: FormMessage;
    public tabIndex = 0;
    public isLoading = false;
    public returnURL = '';
    public company: any;
    public logedInEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(
      private cdRef: ChangeDetectorRef,
      private authenticationService: AuthenticationService,
      private elementRef: ElementRef,
      private translateService: TranslateService,
      @Inject(DOCUMENT) private document: HTMLDocument,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.company = data.company;
        this.returnURL = data.returnURL;
    }

    ngOnInit(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('loading');

        this.authenticationService.logout();
        this.credentials = new AuthenticationModel();
        this.setCompanyStyles();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    login(): void {
        this.isLoading = true;
        this.message = null;
        this.authenticationService.login(this.credentials).subscribe(() => {
            this.cdRef.detectChanges();
            this.logedInEvent.emit();
            this.dialog.closeAll();
        }, () => {
            this.message = {
                severity: 'error',
                summary: 'Anmeldung fehlgeschlagen',
                detail: 'Die Anmeldung mit den angegebenen Zugangsdaten ist fehlgeschlagen.',
            };
        }, () => {
            this.isLoading = false;
        });
    }

    resetPassword(): void {
        this.isLoading = true;
        this.message = null;
        this.authenticationService.resetPassword(this.credentials.email).subscribe(() => {
            this.tabIndex = 0;
            this.message = {
                severity: 'success',
                summary: 'Kennwort zurückgesetzt',
                detail: 'Sie können sich jetzt mit dem Kennwort anmelden, das Sie per E-Mail erhalten haben.',
            };
        }, () => {
            this.message = {
                severity: 'error',
                summary: 'Benutzer nicht bekannt',
                detail: 'Es existiert kein Benutzerkonto unter der angegebenen E-Mail-Adresse',
            };
        }, () => {
            this.isLoading = false;
        });
    }

    public createLogin(): void {
        this.authenticationService.createUser(this.credentials.email).subscribe(() => {
            this.tabIndex = 0;
            this.message = {
                severity: 'success',
                detail: 'Credentials sent! Check your e-mail.',
            };
        }, (error) => {
            console.log(error);
            this.message = {
                severity: 'error',
                detail: this.translateService.instant(error.error),
            };
        }, () => {
            this.isLoading = false;
        });
    }

    onTabChange(index): void {
        this.tabIndex = index;
        this.message = null;
    }

    protected setCompanyStyles(): void {
        const theme = this.company.theme;
        for (const property of Object.keys(theme)) {
            this.document.body.style.setProperty(property, theme[property]);
        }
    }

}
