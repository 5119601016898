import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCommonModule, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
// import * as Sentry from "@sentry/browser";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtModule} from '@auth0/angular-jwt';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {MonacoEditorModule} from 'ngx-monaco-editor';

// import 'rxjs/add/operator/toPromise';
import {EmailService} from './admin/email-test/email.service';

import {AppComponent} from './app.component';
import {AppFooterComponent} from './app.footer.component';
import {AppRoutes} from './app.routes';
import {AppTopbarComponent} from './app.topbar.component';
import {AuthenticationGuard} from './authentication/authentication.guard';
import {AuthenticationInterceptor} from './authentication/authentication.interceptor';
import {AuthenticationResolver} from './authentication/authentication.resolver';
import {AuthenticationService} from './authentication/authentication.service';
import {getAccessToken} from './helpers';
import {HomeComponent} from './home/home.component';
import {AppTopmenuComponent} from './layout/app.topmenu.component';
import {LoginDialogComponent} from './login/login-dialog/login-dialog.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {IframePdfViewerComponent} from './shared/components/iframe-dialog/iframe-pdf-viewer.component';
import {GlobalErrorHandler} from './shared/interceptors/global-error-handler';
import {LocalizationInterceptor} from './shared/interceptors/localization.interceptor';
import {CampaignService} from './shared/services/api/campaign.service';
import {CompanyResolver} from './shared/services/api/company.resolver';
import {LocalizationService} from './shared/services/localization.service';
import {MessageService} from './shared/services/message.service';

// Sentry.init({
//     dsn: environment.sentryKey
// });
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler
// {
//     constructor()
//     {
//     }
//
//     handleError(error)
//     {
//         if (environment.sentryEnabled) {
//             Sentry.captureException(error.originalError || error);
//         }
//
//         console.error(error.originalError || error);
//     }
// }

registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatCommonModule,
        MatSnackBarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient],
            },
        }),
        JwtModule.forRoot({
            config: {
                skipWhenExpired: true,
                tokenGetter: getAccessToken,
                allowedDomains: [
                    'localhost:4200',
                    'ems3.local',
                    'dev-ems3.eflow.one',
                    'test-eflow.mobilejazz.com',
                    'ems3.eflow.one',
                ],
            },
        }),
        MonacoEditorModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        AppTopbarComponent,
        AppTopmenuComponent,
        AppFooterComponent,
        LoginComponent,
        LoginDialogComponent,
        HomeComponent,
        LogoutComponent,
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocalizationInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE',
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        AuthenticationResolver,
        CompanyResolver,
        CampaignService,
        EmailService,
        LocalizationService,
        IframePdfViewerComponent,
        MessageService,
        // {
        //     provide: ErrorHandler,
        //     useClass: SentryErrorHandler
        // }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LoginDialogComponent,
    ],
})
export class AppModule {
}

export function TranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
