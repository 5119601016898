import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {Email} from './email.model';

@Injectable()
export class EmailService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public sendTestEmail(email: Email): Observable<any> {
        return this.http.post([environment.serverPath, 'test-email'].join('/'), email, {responseType: 'text'});
    }
}
