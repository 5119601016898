import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

interface Message {
    severity: 'success' | 'warning' | 'error';
    summary?: string;
    detail: string;
    time?: number;
}

@Injectable()
export class MessageService {

    readonly messageConfiguration: MatSnackBarConfig = {
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
    };

    constructor(private snackBar: MatSnackBar) {
    }

    public add(message: Message): void {

        if (message.time) {
            this.messageConfiguration.duration = message.time;
        }

        let messageString = message.summary;
        messageString = messageString ? messageString.toUpperCase() + '\n' + message.detail : message.detail;

        this.snackBar.open(
            messageString,
            null,
            {
                ...this.messageConfiguration,
                panelClass: [message.severity],
            },
        );
    }
}
