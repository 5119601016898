import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthenticationService} from '../authentication/authentication.service';
import {User, UserRole} from '../shared/models/user.model';

enum MenuOrientation {
    STATIC,
    OVERLAY,
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    sidebarActive = true;
    layoutMode: MenuOrientation = MenuOrientation.STATIC;
    topbarMenuActive: boolean;
    sidebarClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: any;
    documentClickListener: () => void;
    company: any;

    public authenticatedUser: User;
    public showWarningDevelopment = false;
    public showWarningStaging = false;
    public showWarningProduction = false;

    constructor(
        public renderer: Renderer2,
        private route: ActivatedRoute,
        private serviceTitle: Title,
        private authenticationService: AuthenticationService,
        @Inject(DOCUMENT) private document: HTMLDocument,
    ) {
        this.authenticationService.user.subscribe((user: User) => {
            this.authenticatedUser = user;
        });
    }

    get overlay(): boolean {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    public ngOnInit(): void {
        this.company = this.route.snapshot.data.company;
        this.setMetaTitle();
        this.setMetaFavicon();
        this.setCompanyStyles();
        this.setMessageForDevelopers();
    }

    public ngAfterViewInit(): void {
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }
            if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }
            this.topbarItemClick = false;
            this.sidebarClick = false;
        });

        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('loading');
    }

    isDesktop(): boolean {
        return window.innerWidth > 1024;
    }

    ngOnDestroy(): void {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

    protected setMetaTitle(): void {
        this.serviceTitle.setTitle(this.company.name + ' | EMS3');
    }

    protected setMetaFavicon(): void {
        const pathFavicon = '/assets/env/' + this.company.env + '/favicon/';

        this.document.getElementById('appFavicon').setAttribute('href', pathFavicon + 'favicon.ico');
        this.document.getElementById('appFavicon32').setAttribute('href', pathFavicon + 'favicon-32x32.png');
    }

    protected setCompanyStyles(): void {
        const theme = this.company.theme;
        for (const property of Object.keys(theme)) {
            this.document.body.style.setProperty(property, theme[property]);
        }
    }

    protected setMessageForDevelopers(): void {
        if (
            environment.production === false
            || this.authenticatedUser.role !== UserRole.Developer
        ) {
            return;
        }

        if (this.company.real_env === 'development') {
            this.showWarningDevelopment = true;
        }

        if (this.company.real_env === 'staging') {
            this.showWarningStaging = true;
        }

        if (this.company.real_env === 'production') {
            this.showWarningProduction = true;
        }
    }
}
