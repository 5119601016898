import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';
import {LoginDialogComponent} from './login-dialog/login-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    private company: any;
    private returnURL = '';

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        authenticationService.logout();

        route.url.subscribe(() => {
            this.returnURL = route.snapshot.queryParams.returnUrl ? route.snapshot.queryParams.returnUrl : '';
        });
    }

    ngOnInit(): void {
        this.company = this.route.snapshot.data.company;

        const dialogRef = this.dialog.open(LoginDialogComponent, {
            width: '450px',
            panelClass: 'no-padding-dialog',
            data: {
                company: this.company,
                returnURL: this.returnURL,
            },
            disableClose: true,
        });

        dialogRef.componentInstance.logedInEvent.subscribe(() => {
            this.router.navigate(['/' + this.returnURL]);
        });
    }
}
