import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LocalizationService {
    readonly DEFAULT_LANG = 'en';
    readonly AVAILABLE_LOCALES = Array(
        'en',
        'de',
    );

    private local_storage_language_variable = 'ems_language';
    private current_language: string;
    public currentLocale: BehaviorSubject<string>;

    constructor(private translateService: TranslateService) {
        this.current_language = this.getCurrentLanguage();
        this.currentLocale = new BehaviorSubject(this.getCurrentLanguage())
    }

    protected getCurrentLanguage(): string {
        return window.localStorage.getItem(
            this.local_storage_language_variable) || this.getNavigatorLanguageOrDefault();
    }

    private getNavigatorLanguageOrDefault(): string {
        window.navigator.languages.forEach((language) => {
            let language_without_country = language.slice(0, 2);

            if (this.AVAILABLE_LOCALES.includes(language_without_country)) {
                return language_without_country;
            }
        });

        return this.DEFAULT_LANG;
    }

    public setLanguage(lang: string): void {
        this.current_language = lang;
        this.currentLocale.next(lang);
        this.translateService.use(lang);
        window.localStorage.setItem(this.local_storage_language_variable, lang);
    }

    public getLanguage(): string {
        return this.current_language;
    }

    public init(): void {
        this.translateService.setDefaultLang(this.current_language);
    }
}
