import {SerializableObject} from './serializable-object';

export class User extends SerializableObject {
    public id: number;
    public name: string;
    public email: string;
    public phone: string;
    public role: number;
    public password: string;
    public created_at: Date;
    public isSelected = false;
}

export enum UserRole {
    Developer = 0,
    Employee = 10,
    Customer = 20,
}
