import {Campaign} from './campaign.model';
import {SerializableObject} from './serializable-object';
import {Affinity} from "../services/api/affinity.service";

export class SendingMetrics extends SerializableObject {
    public readonly sent_at: string;
    public readonly clicks: number;
    public readonly opens: number;
    public readonly bounces_soft: number;
    public readonly bounces_hard: number;
    public readonly unsubscriptions: number;
    public readonly volume: number;
}

export class Sending extends SerializableObject {

    public id: number;
    public parent: Sending;
    public children: Sending[];
    public affinity: Affinity;
    public campaign_id: Campaign;
    public campaign: Campaign;
    public template_id: number;
    public name: string;
    public selection: string;
    public scheduled_at: Date;
    public sent_at: Date;
    public volume_planned: number;
    public sendfx_id: number;
    public promio_letter_id: number;
    public episerver_id: number;
    public volume_sent: number;
    public opens: number;
    public clicks: number;
    public opens_unique: number;
    public clicks_unique: number;
    public bounces_hard: number;
    public bounces_soft: number;
    public unsubscriptions: number;
    public is_booked = false;
    public share_statistics = false;
    public created_at: Date;
    public updated_at: Date;
}
