import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {MessageService} from '../services/message.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private ngZone: NgZone,
        private messageService: MessageService,
        private translateService: TranslateService,
    ) {
    }

    public handleError(error: any): void {
        // Note : Unhandled promises are wrapped into "rejection" property by zone.js
        // See https://github.com/angular/angular/issues/27840
        this.handleErrorNormalized(error.rejection ? error.rejection : error);
    }

    public handleHttpError(error: HttpErrorResponse): void {
        this.messageService.add({
            severity: 'error',
            summary: error.statusText,
            detail: error.message,
        });
    }

    private handleErrorNormalized(error: Error): void {

        if (!environment.production) {
            throw error;
        } else {
            if (error instanceof HttpErrorResponse) {
                this.handleHttpError(error);
            } else {
                this.handleGenericError(error);
            }
        }
    }

    private handleGenericError(error: Error): void {
        this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant('error.genericMessage'),
        });
    }
}
