import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-iframe-dialog',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
    public resourceURL: SafeResourceUrl;

    constructor(
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.resourceURL = data.resourceURL;
    }
}
