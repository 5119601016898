import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../../environments/environment';
import {DatatableResponse} from '../../datatable-response';
import {hasValue} from '../../helpers';
import {Action} from '../../models/action.model';
import {Campaign, CampaignParams} from '../../models/campaign.model';
import {Template} from '../../models/template.model';
import {TestEmailModel} from "../../models/test-email.model";
import {TableSortModel} from "../../models/table-sort.model";
import {BehaviorSubject} from "rxjs";

export interface ICampaignGetAllFilter {
    start_filter?: Date;
    finish_filter?: Date;
    status?: number;
    campaign?: string;
    organization?: number;
}

export interface ICampaignGetAllParameters {

    // eager loaded entities

    affinity?: boolean;
    sendings?: boolean;

    // appended attributes

    affinity_stats?: boolean;
    customer_stats?: boolean;
    serialized_billing_types?: boolean;
    status?: boolean;
    send_cpm_cost?: boolean;
    bounce_cost?: boolean;
    unsubscribe_cost?: boolean;
    turnover?: boolean;
}

export interface ICampaignSummations {
    totalNetto?: number;
}

@Injectable()
export class CampaignService {

    private vatDefaultValue = 0.16; // Default until 2021

    private base_url = `${environment.serverPath}/v2/campaigns`;
    public vat$: BehaviorSubject<number> = new BehaviorSubject(this.vatDefaultValue);

    constructor(
        private http: HttpClient,
    ) {
    }

    public updateVat(vat: number): void {
        this.vat$.next(vat);
    }

    public getAll(
        offset = 0,
        rows = 100,
        filters?: ICampaignGetAllFilter,
        includes?: ICampaignGetAllParameters,
        sort?: TableSortModel,
    ): Observable<DatatableResponse> {
        const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
        const params: any = {};

        params.offset = offset;
        params.rows = rows;

        if (sort && sort.field) {
            params.sortField = sort.field;
            params.sortDirection = sort.direction;
        }

        Object
            .entries(filters || {})
            .filter(([key, value]) => hasValue(value))
            .forEach(([filter, value]) => {
                params[filter] = value;
            });

        if (includes) {
            params['includes'] = Object.keys(includes).join(',');
        }

        if (params.start_filter) {
            params.start_filter = moment(params.start_filter).format(DATE_FORMAT);
        }

        if (params.finish_filter) {
            params.finish_filter = moment(params.finish_filter).format(DATE_FORMAT);
        }

        return this.http.get(this.base_url, {params}).map((response: DatatableResponse) => {
            response.rows = response.rows.map((row) => {
                return new Campaign(row);
            });

            return response;
        });
    }

    public getSummations(
        filters?: ICampaignGetAllFilter,
        summations?: ICampaignSummations,
    ): Observable<ICampaignSummations> {

        if (!summations) {
            return;
        }

        const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
        const params: any = {};

        Object
            .entries(filters || {})
            .filter(([key, value]) => hasValue(value))
            .forEach(([filter, value]) => {
                params[filter] = Array.isArray(value) ? value.toString() : value;
            });

        if (params.start_filter) {
            params.start_filter = moment(params.start_filter).format(DATE_FORMAT);
        }

        if (params.finish_filter) {
            params.finish_filter = moment(params.finish_filter).format(DATE_FORMAT);
        }

        params['includes'] = Object.keys(summations).join(',');

        return this.http.get([this.base_url, 'summations'].join('/'), {params}).map(
            (response: ICampaignSummations) => {
                return response;
            },
        );
    }

    public plan(offerId: number): Observable<Campaign> {
        return this.http.post([environment.serverPath, 'offers', offerId, 'campaigns'].join('/'), offerId).map(
            (response: Response) => {
                return new Campaign(response);
            },
        );
    }

    public create(campaign: Campaign): Observable<Campaign> {
        return this.http.post([this.base_url, campaign.id].join('/'), campaign).map(
            (response: Response) => {
                return new Campaign(response);
            },
        );
    }

    public delete(campaign: Campaign): Observable<any> {
        // TODO add an interceptor https://trello.com/c/OXYFjZTG/202-add-interceptors-to-204-responses
        return this.http.delete([this.base_url, campaign.id].join('/'), {responseType: 'text'});
    }

    public update(campaign: Campaign): Observable<Campaign> {
        return this.http.put([this.base_url, campaign.id].join('/'), campaign).map(
            (response: Response) => {
                return new Campaign(response);
            },
        );
    }

    public updateAmounts(campaign: Campaign, amounts: CampaignParams): Observable<any> {
        return this.http.put([this.base_url, campaign.id, 'amounts'].join('/'), amounts);
    }

    public sendStats(campaignId: number): Observable<any> {
        return this.http.post([this.base_url, campaignId, 'send-stats'].join('/'), {});
    }

    public showStats(campaignId: number): Observable<any> {
        return this.http.get([this.base_url, campaignId, 'campaign-stats'].join('/'), {
            responseType: 'blob',
            headers: {
                'Accept': 'application/pdf',
            },
        });
    }

    public getById(campaignId: number): Observable<Campaign> {
        return this.http.get([this.base_url, campaignId].join('/')).map(
            (response: Response) => {
                return new Campaign(response);
            },
        );
    }

    public createTemplate(campaignId: number): Observable<Template> {
        return this.http.post([this.base_url, campaignId, 'templates'].join('/'), campaignId).map(
            (response: Response) => {
                return new Template(response);
            },
        );
    }

    public deleteTemplate(template: Template): Observable<any> {
        return this.http.delete([this.base_url, template.campaign_id, 'templates', template.id].join('/'));
    }

    public updateTemplate(template: Template): Observable<Template> {
        return this.http.put([this.base_url, template.campaign_id, 'templates', template.id].join('/'), template).map(
            (response: Response) => {
                return new Template(response);
            },
        );
    }

    public sendReminder(template: Template): Observable<any> {
        return this.http.post(
            [this.base_url, template.campaign_id, 'templates', template.id, 'send-reminder'].join('/'), {});
    }

    public sendTest(template: Template, templateHTML: string, testEmail: TestEmailModel): Observable<any> {
        return this.http.post(
            [this.base_url, template.campaign_id, 'templates', template.id, 'send-test'].join('/'), {templateHTML, ...testEmail});
    }

    public uploadTemplate(template: Template): Observable<Template> {
        return this.http.put([this.base_url, template.campaign_id, 'templates', template.id].join('/'), template).map(
            (response: Response) => {
                return new Template(response);
            },
        );
    }

    public approveTemplate(template: Template): Observable<Template> {
        return this.http.put([this.base_url, template.campaign_id, 'templates', template.id, 'approve'].join('/'),
            template).map(
            (response: Response) => {
                return new Template(response);
            },
        );
    }

    public getActions(campaign: Campaign): Observable<Action[]> {
        return this.http.get([this.base_url, campaign.id, 'action'].join('/')).map(
            (response: Action[]) => {
                return response;
            }
        );
    }
}
