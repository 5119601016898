import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';
import {AuthenticationModel} from '../shared/models/authentication.model';
import {User, UserRole} from '../shared/models/user.model';

@Injectable()
export class AuthenticationService {
    public user: BehaviorSubject<User> = new BehaviorSubject(null);
    private timeout;

    constructor(
        private http: HttpClient,
    ) {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            this.user.next(user);
        }
    }

    public login(credentials: AuthenticationModel) {
        return this.http.post(environment.serverPath + '/login', {
            email: credentials.email,
            password: credentials.password,
        }).map((response) => {
            this.saveJWT(response);
        });
    }

    public refreshTokenAndSetRenewTimeout() {
        return this.refreshToken();
    }

    public refreshToken() {
        let expireTokenDate = parseInt(localStorage.getItem('expire_token_date'));
        // We will refresh the token only 1 hour before the expiration time
        let expireTokenDateLessOneHour = expireTokenDate - (60 * 60 * 60);
        let now = new Date();

        if (now.getTime() < expireTokenDateLessOneHour) {
            return;
        }

        return this.http.get(environment.serverPath + '/refresh').subscribe((response) => {
            this.saveJWT(response);
        });
    }

    public logout() {
        return Observable.create((observer: Observer<any>) => {
            this.http.get(environment.serverPath + '/logout').subscribe((response) => {
                this.eraseJWT();
                observer.next(true);
            });
        });
    }

    protected saveJWT(response) {
        if (response.hasOwnProperty('access_token')) {
            localStorage.setItem('access_token', response['access_token']);
        }

        if (response.hasOwnProperty('user')) {
            const user = new User(response['user']);
            localStorage.setItem('user', JSON.stringify(user));
            this.user.next(user);
        }

        if (response.hasOwnProperty('expires_in_minutes')) {
            let now = new Date();
            let expireTokenDate = new Date();
            expireTokenDate.setTime(now.getTime() + (response['expires_in_minutes'] * 60 * 1000));
            localStorage.setItem('expire_token_date', expireTokenDate.getTime().toString());
        }

        this.timeout = setTimeout(() => {
            this.refreshToken();
        }, 1000 * 60 * 5);
    }

    protected eraseJWT() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('expire_token_date');
        this.user.next(null);
        clearTimeout(this.timeout);
    }

    public createUser(email: string): Observable<any> {
        return this.http.post(environment.serverPath + '/create-login', {
            email: email,
        });
    }

    public resetPassword(email: string) {
        return this.http.post(environment.serverPath + '/reset-password', {
            email: email,
        });
    }

    public isAdminUser(): boolean {
        return this.user.value.role === UserRole.Employee
            || this.user.value.role === UserRole.Developer;
    }

    public isCustomerUser(): boolean {
        return this.user.value.role === UserRole.Customer;
    }
}
