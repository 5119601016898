import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import {environment} from '../../../../environments/environment';
import {Company} from '../../models/company.model';

@Injectable()
export class CompanyResolver implements Resolve<Observable<any>> {

    private company: Company;

    constructor(private http: HttpClient) {
    }

    resolve(): Observable<Company> {
        if (this.company) {
            return of(this.company);
        } else {
            return this.http.get(environment.serverPath + '/company').map(
                (companyData: Object) => {
                    this.company = new Company(companyData);
                    return this.company;
                },
            );
        }
    }
}
