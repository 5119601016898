import {SerializableObject} from './serializable-object';

export class Company extends SerializableObject {

    address: string;
    brand_app_logo: string;
    city: string;
    country: string;
    env: string;
    real_env: string;
    logo: string;
    name: string;
    postcode: string;
    terms_url: string;
    theme: any;

    protected coercePropertyType(propertyName: string, propertyValue: any) {
        switch (propertyName) {
            default:
                return propertyValue;
        }
    }

}
