export interface IEnum {
    value: number | string;
    label: string;
    icon?: string;
}

/**
 * Adds convenient getters for an enum field.
 *
 * E.g. for field `status` and properties of the enum
 * `label` & `icon` it'll add the following getters:
 *     - method: `getStatusLabel`, `getStatusIcon`
 *     - property: `statusLabel`, `statusIcon`
 *
 * @param field Enum field to add getter on, e.g.: `status`
 * @param enums List of enums that implement IEnum interface
 * @param props List of properties of the enum to add getters
 */
export function EnumField(field: string, enums: IEnum[], props: string[] = ['label', 'icon']) {
    function firstToLower(str: string) {
        return str.charAt(0).toLowerCase() + str.slice(1);
    }

    function capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return function(target) {
        const capitalizedCol = field.split('_').map(capitalize).join('');

        props.forEach(prop => {
            const property = firstToLower(capitalizedCol + capitalize(prop));
            const method = 'get' + capitalizedCol + capitalize(prop);

            function getValue() {
                return enums.find(e => e.value === this[field])[prop];
            }

            // Method getter
            target.prototype[method] = getValue;

            // Property getter
            Object.defineProperty(target.prototype, property, {
                get: getValue,
            });
        });
    };
}
