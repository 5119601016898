import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication/authentication.service';
import {LocalizationService} from './shared/services/localization.service';

enum MenuOrientation {
    STATIC,
    OVERLAY,
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

    sidebarActive = true;
    layoutMode: MenuOrientation = MenuOrientation.STATIC;
    topbarMenuActive: boolean;
    sidebarClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: any;
    documentClickListener: Function;

    constructor(
        public renderer: Renderer2,
        private authenticationService: AuthenticationService,
        private router: Router,
        private localizationService: LocalizationService,
    ) {
        this.localizationService.init();
    }

    get overlay(): boolean {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        this.documentClickListener = this.renderer.listen('body', 'click', () => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }
            if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }
            this.topbarItemClick = false;
            this.sidebarClick = false;
        });
    }
    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }
}
