import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalizationService} from '../services/localization.service';

@Injectable()
export class LocalizationInterceptor implements HttpInterceptor {

    constructor(
        private localizationService: LocalizationService,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const app_locale = this.localizationService.getLanguage();

        const authReq = req.clone({
            headers: req.headers.set('Accept-Language', app_locale),
        });

        return next.handle(authReq);
    }
}
