import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import * as Bowser from 'bowser';
import {IframeDialogComponent} from './iframe-dialog.component';

@Injectable()
export class IframePdfViewerComponent {
  private readonly browsers_with_pdf_preview = ['Chrome'];
  private readonly browser: string;

  constructor(
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) {
    this.browser = Bowser.getParser(window.navigator.userAgent)
      .getBrowser().name;
  }

  public openOrDownload(pdf_url: string): void {
    if (!this.isBrowserWithPdfPreview()) {
      pdf_url = `https://docs.google.com/gview?embedded=true&url=${pdf_url}`;
    }

    const dialogRef = this.dialog.open(IframeDialogComponent, {
      height: '90vh',
      width: '95vw',
      panelClass: 'wide',
      data: {
        resourceURL: this.sanitizer.bypassSecurityTrustResourceUrl(pdf_url),
      },
    });
  }

  private isBrowserWithPdfPreview(): boolean {
    return this.browsers_with_pdf_preview.includes(this.browser);
  }
}
