import {EnumField, IEnum} from '../decorators/enum-field.decorator';
import {SerializableObject} from './serializable-object';
import {Affinity} from "../services/api/affinity.service";
import {Sending} from "./sending.model";

export class TemplateVariable extends SerializableObject {
    id: number;
    emsFormat: string;
    dummyData: string;
    sendEffectFormat: string;
    epiServerFormat: string;
}

export enum TemplateFormat {
    Episerver,
    SendEffect,
}
export const TEMPLATE_FORMAT_NAMES: IEnum[] = [
    { value: TemplateFormat.SendEffect, label: 'SendEffect' },
    { value: TemplateFormat.Episerver, label: 'EpiServer' },
];

export enum TemplateStatus {
    MISSING,
    APPROVAL_MISSING,
    APPROVED,
    SENT,
}
export const TEMPLATE_STATUS_NAMES: IEnum[] = [
    {value: TemplateStatus.MISSING, label: 'Template missing'},
    {value: TemplateStatus.APPROVAL_MISSING, label: 'Approval pending'},
    {value: TemplateStatus.APPROVED, label: 'Approved'},
];

@EnumField('status', TEMPLATE_STATUS_NAMES)
export class Template extends SerializableObject {
    public id: number;
    public campaign_id: number;
    public html_code: string;
    public text_code: string;
    public affinity: Affinity;
    public name: string;
    public date: Date;
    public status: number;
    public sendings: Sending[];

    protected stats: any;

    isSent() {
        return this.status >= TemplateStatus.APPROVED || this.getStats().sentVolume > 0;
    }

    getStats() {
        // If already defined, return cached stats
        if (this.stats) {
            return this.stats;
        }

        let hasSendings = this.sendings && this.sendings.length > 0;

        const bookedVolume = hasSendings ? this.sendings.reduce((total, sending) => total + sending.volume_planned,
            0) : 0;
        const sentVolume = hasSendings ? this.sendings.reduce((total, sending) => total + sending.volume_sent, 0) : 0;
        const clicksUnique = hasSendings ? this.sendings.reduce((total, sending) => total + sending.clicks_unique,
            0) : 0;
        const clicksUniquePercent = sentVolume ? clicksUnique / sentVolume : 0;
        const opensUnique = hasSendings ? this.sendings.reduce((total, sending) => total + sending.opens_unique, 0) : 0;
        const opensUniquePercent = sentVolume ? opensUnique / sentVolume : 0;

        const stats: any = {
            bookedVolume,
            sentVolume,
            clicksUnique,
            clicksUniquePercent,
            opensUnique,
            opensUniquePercent,
        };

        // Cache stats
        this.stats = stats;

        return stats;
    }

    protected coercePropertyType(propertyName: string, propertyValue: any) {
        switch (propertyName) {
            case 'sendings':
                if (propertyValue.length > 0) {
                    return propertyValue.map((c) => new Sending(c));
                }
                return propertyValue;
            default:
                return propertyValue;
        }
    }
}
