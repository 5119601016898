import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthenticationService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!localStorage.getItem('access_token')) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }

        if (this.auth.isAdminUser()) {
            return true;
        }

        const expectedRole = route.data.expectedRole;

        if (
            expectedRole
            && this.auth.user.value.role !== expectedRole
        ) {
            this.router.navigate(['/offers']);

            return false;
        }

        return true;
    }
}
